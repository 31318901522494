.updateItPassword {

    .paswordtoolbox:before {
        content: "";
        background-color: #fff;
        border-left: 1px solid #f1e5e5;
        border-bottom: 1px solid #f1e5e5;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 68px;
        transform: rotate(45deg);
        right: 0px;
        left: -11px;
    }

    .paswordtoolbox {
        position: absolute;

        z-index: 333;
        padding: 3px 10px;
        top: -4rem !important;
        right: -3rem !important;

        background-color: #fff;
        box-shadow: 2px 2px 3px #fbf6f6;
        border-radius: 2px;
        border: 1px solid #f1e5e5;
        display: block;
    }

    .passlist {
        padding: 0px 2px 0px 5px;
    }

    .passlist span {
        font-size: 9px;
    }

    p.passmust {
        font-size: 13px;
        padding: 0px 4px;
        margin-bottom: 0px;

    }

    span.passlisticon {
        padding: 0px 6px 0px 0px;
        color: #e7dede;
        font-size: 9px;
    }

    span.passlisticon:hover {

        color: #fc7d72;
    }

    p.alsintextsiz {
        font-size: 14px;
    }
}