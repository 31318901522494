/* ::-webkit-scrollbar {
  width: 0.8em;
  height: 0.5em;
}
::-webkit-scrollbar-button {
  background: #ccc;
}
::-webkit-scrollbar-track-piece {
  background: #ddd;
}
::-webkit-scrollbar-thumb {
  background: #eee;
} */

header {
  position: sticky;
  top: 0px;
  z-index: 10;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28) !important;
  background-color: #fff;
}

li.nav-item.dropdown.userprodropdlist.show .dropdown-menu.dropdown-menu-right.show button.dropdown-item {
  border-radius: 0px !important;
  padding: 5px 9px 5px 9px !important;
}

.headerlogodispinle {
  display: inline-flex;
}

ul.compboxtoppadd {
  /* min-height: 47px; */
  top: 0.5rem;
  position: relative;
  padding-left: 0px;
}

main header nav.navbar {
  padding: 0 3px !important;
}

header nav {
  margin-bottom: 0px !important;
  border: 0px !important;
  background-color: #fff;
}

a.navbar-brand.mainexplogo img {
  width: 100px;
}

header ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 0px;
}

li.headullipaddreight {
  padding-right: 0;
  position: relative;
  left: 0.8rem;
  z-index: 9;
}

span.boxalign {
  right: 0rem;
  left: 0.5rem;
  position: relative;
}

.taxtoppadd {
  padding-bottom: 0.2rem;
}

header ul li.dropdmenufr {
  border-radius: 50%;
  padding: 10px 7px 6px 11px;

  /* margin-top: 15px; */

  ul.headernavdropmd span {
    border-radius: 50%;
  }
}

.header-sticky {
  position: fixed;
  top: 78px;
  left: 25%;
  z-Index: 100;
  width: 50%;
  text-align: center;
}

.userprodropd .dropdown-menu.show {
  background: #fff;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15), 0 5px 20px 0 rgba(21, 27, 38, 0.08);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  margin-top: 6px;
  border-radius: 0px;
  max-width: 100%;
}

.profile_img_bg {
  background-color: #f2f2f2;
}

.dark_color_text {
  color: #31719b;
}

span.profile_img_bg.dark_color_text {
  padding: 5px 9px 9px;
  border-radius: 50%;
}

.userprodropd ul li {
  margin-right: 0px;
}

li.nav-item.dropdown.userprodropdlist a#navbardrop {
  padding: 0.5rem 0.1rem !important;
}

span {
  &.checkiconusr {
    font-size: 12px !important;
    padding-left: 0px;
    color: #333;
  }

  &.logicntopm {
    margin-top: -9px;
    max-width: 100%;

    img {
      width: 15px;
      height: 15px;
      padding: 0px;
      float: right;
      top: 3px;
      position: relative;
    }
  }
}

.dropdown-menu.dropdown-menu-right.show .dropdown-divider {
  height: 0;
  margin: .3rem 0 !important;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

a.dropdown-item.profileusr span {
  font-size: 0.8rem;
}

.userprodropd a.dropdown-item {
  &.profileusr {
    padding: 0.1rem 0.6rem !important;
  }

  font-size: 0.8rem;
  padding: .1rem 0rem !important;
}

.lipaddmanage span {
  padding: 10px 7px 6px 3px;
}

span.float-right.rightagliconmore {
  img {
    width: 15px;
    height: 15px;
    padding: 0px;
    margin: 0px;
    color: #333;
  }

  margin-top: -12px;
}

.dropdown-item.profileusr {
  padding: 0.2rem 0.5rem;
}

span {
  &.black_color_text.usertextoverflow {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    cursor: pointer;
    display: inline-block;
  }

  &.activeusrp {
    color: #4caf50 !important;
    font-size: 9px !important;
    float: right;
    top: 3px;
    position: relative;
  }
}

button.dropdown-item.logdisinline.btn.btn-primary {
  padding: 0.2rem 0.5rem;
}

span.k-widget.k-dropdown.header_select.headerselect {
  width: 68px !important;
  padding: 0px 0px;
  font-size: 12px;

  &.monthelbox {
    width: 47px !important;
  }

  span {
    &.k-dropdown-wrap {
      margin-top: -2px;
      border-color: #f2f2f2;
      color: #656565;
      background-color: #f2f2f2 !important;
      float: right;
      padding: 0;
      background-image: linear-gradient(#f2f2f2, #f2f2f2) !important;
    }

    &.k-select {
      padding: 0px 0px;
      width: calc(0.9em + 5px);
    }
  }
}

.refrsh_sync {
  margin: 0px 8px;
  cursor: pointer;
  color: #31719b;
}

.headerselectoption .k-list {
  >.k-item {
    color: #656565;
    font-size: 12px;
    font-weight: bold;
    padding-top: 1px;
    padding-bottom: 1px;
    border-bottom: 1px solid #f2f2f2;
  }

  .k-item.k-state-selected {
    color: #fff !important;
  }
}

.k-list-optionlabel.k-state-selected {
  color: #fff !important;
}

.infoiconpan {
  display: inline-block;
  cursor: pointer;
}

span {
  &.infoiconpan {
    position: absolute;
    top: -1.8px;
    right: -13px;
    font-size: 10px;
    cursor: pointer;
  }
}

.rotate_icon {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.disabledoption {
  pointer-events: none;
  opacity: 0.4;
  cursor: default;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

a.navbar-brand.mainexplogo {
  border-color: #66afe900 !important;
  outline: 0;
  box-shadow: inset 0 2px 2px rgb(0 0 0 / 0%), 0 0 8px rgb(102 175 233 / 0%) !important;
}

/* Start call me */

li.nav-item.dropdown.callmemainbox {
  top: 0.3rem;
}

span.callicon img {
  width: 15px;
}

.dispinlinerightnav {
  float: right;
  display: inline-flex;
}

.callmebgclr {
  background-color: #31719b;
  color: #fff;
  padding: 1px 4px 3px 4px !important;
  position: relative;
  border-radius: 4px;
  font-size: 13px;
  top: 3px;
  cursor: pointer;

  span {
    padding: 2px;
  }

  &:hover {
    color: #fff;
  }
}

.nav-link.dropdown-toggle.callmebgclr::after {
  display: none;
}

.dropdown-menu.cllmeinnerdown.show {
  top: 3.1rem;
  margin: 0px -5rem;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(111 119 130 / 15%), 0 5px 20px 0 rgb(21 27 38 / 8%);
  box-sizing: border-box;
  font-size: 14px;
  max-width: 100%;
  padding: 0px 1px;
  min-width: 14rem;
}

span.closeiconright {
  float: right;
  color: #31719b;
}

.dropdown-item.headerscetbborder {
  font-size: 12px;
  padding: 0.4rem 0.5rem;
  border-bottom: 1px solid #e8e3e3;
  font-weight: 500;
  border-radius: 0px;
}

.textboxinnersect {
  padding: 0.5rem 0.5rem;
}

p.contentarea {
  font-size: 10.5px;
  color: #909090;
  font-weight: 500;
}

.inputboxarea input.form-control.inputcallme {
  font-size: 12px;
  padding: .37rem .75rem;
  height: calc(1.2em + .75rem + 2px);
  border: 1px solid #c6c2c2;
  outline-color: transparent;
}

.callmebtnbox {
  padding: 0.5rem 0.1rem 0rem 0.1rem;
  text-align: center;

  .callmedonebtn {
    background-color: #33729c;
    color: #fff;
    outline-color: transparent;
    padding: 0.2rem 0.9rem;
    font-size: 12px;
    border-radius: 40px;

    &:hover {
      color: #fff;
    }
  }
}

input.form-control.inputcallme:focus {
  color: #495057;
  background-color: #fff;
  border-color: #33729c;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

a.btn.btn-default.callmedonebtn:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

.dropdown-item.headerscetbborder:active {
  color: #16181b;
  background-color: #cccccc00;
}

.disableRefrshSync {
  color: #93979b;
}

/* end  call me */

span.pandatebox {
  color: #31719b;
}

ul.headernavdropmd {
  position: relative;
  right: 1.5rem;
  top: -3px;
  padding-inline-start: 0;
}

li.exprit_headullipaddreight {
  position: relative;
  right: -1.5rem;
}

ul.callmerightbox {
  position: absolute;
  right: -76px;
  top: -1px;
  padding: 0px;
}

.userprodropd {
  right: 1.5rem;
  position: relative;
}

/*================header toggl btn css========================*/

label.switch.htoggleswit {
  margin-bottom: -0.4rem;
}

/*================header toggl btn css========================*/

span.headertogglebtn {
  top: 4.6px;
  position: relative;
  right: 1.2rem;
}

.switch.htoggleswit {
  position: absolute;
  display: inline-block;
  width: 23px;

  /* 60*/
  height: 11px;

  /*34 */

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

label.switch.htoggleswit {
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #33729c;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 7.3px;
      width: 3px;
      top: 2px;
      left: 3px;
      background-color: rgb(242 242 242);
      transition: .4s;
      outline-color: rgb(242 242 242);
    }
  }

  input:checked+.slider {
    background-color: #cccbcb;

    &:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
      left: -9px;
    }
  }

  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 16%;
    }
  }
}

/* label.switch.htoggleswit input:focus + .slider {
  box-shadow: 0 0 1px #31719b;
} */

/* Rounded sliders */

/*================end header toggl btn css========================*/

.header-hidden {
  position: absolute;
  top: -64px;
  width: 100%;
  animation: all 0.2s ease;
  transition-duration: 0.2s;
}

.header-show {
  position: sticky;
  top: 0px;
  width: 100%;
  animation: all 0.2s ease;
  transition-duration: 0.2s;
}

.dropdown-menu.dropdown-menu-right.show .dropdown-divider {
  height: 0;
  margin: .3rem 0 !important;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

li.nav-item.dropdown.userprodropdlist.show .dropdown-menu.dropdown-menu-right.show button.dropdown-item {
  border-radius: 0 !important;
  padding: 5px 9px !important;
}

span.logicntopm img {
  width: 15px;
  height: 15px;
  padding: 0;
  float: right;
  top: 3px;
  position: relative;
}

.callme_tour {
  height: 210px;
  width: 230px;
  position: absolute;
  left: -145px;
  background-color: #fff;
  display: none;

  &.show {
    display: block;
  }
}

span.k-widget.k-dropdown.header_select.headerselect span.k-dropdown-wrap span.k-input {
  height: calc(0.9em + 5px);
  padding: 0px 0px 0px 5px;
}

/* add new css */

.exprit_infoiconpan_boxbottom {
  display: flex;
}

.exprit_infoiconpan {
  display: inline-flex;
  cursor: pointer;
  vertical-align: super;
}

.exprit_rightsbox_taxtoppadd {
  display: flex;
  padding-bottom: 0.2rem;
  position: relative;
  top: 0px;
}

span {
  &.k-widget.k-dropdown.exprit_header_selectbox.exprit_headerselect span.k-dropdown-wrap {
    margin-top: -2px;
    border-color: #f2f2f2;
    color: #656565;
    background-color: #f2f2f2 !important;
    float: right;
    padding: 0;
    background-image: linear-gradient(#f2f2f2, #f2f2f2) !important;

    span.k-input {
      height: calc(0.9em + 5px) !important;
      padding: 0 0 0 5px !important;
      border: 0px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  &.exprit_loaderboxlpad {
    cursor: pointer !important;
    z-index: 1;
    top: 3px;
    position: relative;

    span#yearDropDownPopup {
      margin-top: -9px;
      border-color: #f2f2f2;
      color: #656565;
      background-color: #f2f2f2 !important;
      background-image: linear-gradient(#f2f2f2, #f2f2f2) !important;
      height: calc(0.9em + 5px) !important;
      border-radius: 0px;
      width: 80px !important;
      padding: 0;
      min-width: 80px;
      max-width: 100px;

      span.k-input-inner {
        padding: 4px 4px !important;
      }
    }
  }
}

.k-animation-container.k-animation-container-relative.headerselectoption.k-animation-container-shown {
  width: 84px;
  padding: 0;
  min-width: 84px;
  max-width: 100px;
  margin-left: -0.1rem;

  .k-list-item.k-selected {
    color: white;
    background-color: #ff6358;
  }
}

.k-selected.k-list-optionlabel {
  color: white;
  background-color: #ff6358;
}

span.exprit_loaderboxlpad {
  button.k-button.k-button-md.k-button-solid.k-button-solid-base.k-rounded-md.k-icon-button.k-input-button {
    display: block;
    border: 0px;
    background-color: transparent !important;
    top: -4px;
    cursor: pointer;
    padding: 1px 11px 0px 0px !important;
    width: 15px;
  }

  span#yearDropDownPopup span.k-input-value-text {
    font-size: 12px;
    font-weight: 500;
  }

  .k-popup>.k-list .k-list-ul .k-list-item.k-selected {
    color: white;
    background-color: #ff6358;
  }
}

.k-selected.k-list-optionlabel {
  color: white;
  background-color: #ff6358;
}

.k-list-md {

  .k-list-item,
  .k-list-optionlabel {
    padding: 1px 8px !important;
    font-size: 12px;
    font-weight: 500;
  }
}

span.exprit_dashboard_iconbox {
  position: relative;
  top: -1px;
}

a.btn.btn-default.expit_computation_btncgcle {
  margin: 0px;
  padding: 0px;
  top: -3px;
  position: relative;
}

/*======== header drop lable box css  ================*/

span {
  &.header_drop_labelbox {
    padding-top: 3px;
  }

  &.header_drop_labelbox_textfs {
    margin-top: -2px;
    color: rgb(252, 125, 114);
    float: right;
    padding: 0px 5px 1px 5px;
    font-size: 12px;
    font-weight: 500;
  }
}

/*======== end header drop lable box css  ================*/

/* start media code */
@media only screen and (min-width: 320px) and (max-width: 374px) {
  .dispinlinerightnav {
    float: right;
    display: block;
  }

  .userprodropd {
    top: 0.8rem;
    position: relative;
    text-align: right;
    right: 1.1rem;
  }

  .client_name_header_fullname {
      max-width: 170px !important;
  }

}

@media only screen and (min-width: 376px) and (max-width: 640px) {
  .dispinlinerightnav {
    float: right;
    display: block;
  }

  .userprodropd {
    top: 0.8rem;
    position: relative;
    text-align: right;
    right: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  a.navbar-brand.mainexplogo img {
    width: 92px !important;
  }

  .headerrightcontent {
    padding: 0px;
  }

  ul.float-right.headernavdropmd li {
    margin-right: 0px;
    float: right;
  }

  .navbar-header.smpaddhead {
    padding: 0px 1px;
  }

  .profilesmfsize {
    font-size: 11px !important;
  }

  li.nav-item.dropdown.userprodropdlist a#navbardrop {
    float: right;
  }

  span.infoiconpan {
    top: 31px;
  }

  .dropdown-menu.cllmeinnerdown.show {
    top: 2.1rem;
    margin: 0px -5rem;
  }
  .client_name_header_fullname {
      max-width: 170px !important;
  }
  .exprit_infoiconpan_boxbottom {
      display: inline-block;
      font-size: 11px;
      font-weight: 500;
    }
    .client_name_header_fullname {
        max-width: 170px !important;
    }



}

@media only screen and (min-width: 768px) and (max-width: 941px) {
  ul.float-right.headernavdropmd {
    display: inline-flex;
  }

  .userprodropd li.nav-item.dropdown.userprodropdlist {
    top: 0.0rem !important;
    right: 0rem;
    left: -3.3rem;
  }

  span.infoiconpan {
    top: 12px;
  }

  .dispinlinerightnav {
    float: right;
    display: block;
  }

  .userprodropd {
    top: -0.2rem;
    position: relative;
    text-align: right;
    right: 0rem;
    left: -1rem;
  }

  .dropdown-menu.cllmeinnerdown.show {
    margin: 0px -5rem;
  }

  .timelinefixed {
    top: 8.8rem !important;
  }

  header ul li {
    list-style-type: none;
    display: block;
    margin-right: 0px;
  }

  ul {
    &.callmerightbox {
      top: 0px;
      right: -3rem;
    }

    &.headernavdropmd {
      right: 0rem;
    }
  }
  .exprit_infoiconpan_boxbottom {
    font-size: 11px;
    font-weight: 500;
  }
  .client_name_header_fullname {
      max-width: 170px !important;
  }
  .client_name_header {
      max-width: 80px !important;
  }
}

@media only screen and (min-width: 942px) and (max-width: 1025px) {
  ul.float-right.headernavdropmd {
    display: inline-flex;
  }

  .userprodropd {
    li.nav-item.dropdown.userprodropdlist {
      right: 1rem;
      left: 0rem;
    }

    top: 0.1rem;
    left: -0.5rem;
  }

  .timelinefixed {
    top: 5.5rem !important;
  }

  ul {
    &.headernavdropmd {
      position: relative;
      right: 2.6rem;
      top: -0.2rem;
    }

    &.callmerightbox {
      position: absolute;
      right: -77px;
      top: -10px;
    }
  }

  header ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 6px;
  }

  li.headullipaddreight {
    padding-right: 11px;
  }

  .callmebgclr {
    top: 12px;
  }

  .exprit_infoiconpan_boxbottom {
    font-size: 11px;
    font-weight: 500;
  }
  .client_name_header_fullname {
    max-width: 300px !important;
    font-size: 12px;
    font-weight: 500;
  }
  .client_name_header {
      max-width: 80px !important;
  }
}

@media  (min-width: 1026px) and (max-width: 1171px) {
  ul.float-right.headernavdropmd {
    display: inline-flex;
  }

  .userprodropd {
    li.nav-item.dropdown.userprodropdlist {
      right: 1rem;
      left: 0rem;
    }

    top: 0.1rem;
    left: -0.5rem;
  }

  .timelinefixed {
    top: 5.5rem !important;
  }

  ul {
    &.headernavdropmd {
      position: relative;
      right: 2.6rem;
      top: -0.2rem;
    }

    &.callmerightbox {
      position: absolute;
      right: -77px;
      top: -10px;
    }
  }

  header ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 6px;
  }

  li.headullipaddreight {
    padding-right: 11px;
  }

  .callmebgclr {
    top: 12px;
  }

  .exprit_infoiconpan_boxbottom {
    font-size: 11px;
    font-weight: 500;
  }
  .client_name_header_fullname {
    max-width: 300px !important;
    font-size: 12px;
    font-weight: 500;
  }
  .client_name_header {
      max-width: 80px !important;
  }
}

@media  (min-width: 1172px) and (max-width: 1281px) {
  ul.float-right.headernavdropmd {
    display: inline-flex;
  }

  .userprodropd {
    li.nav-item.dropdown.userprodropdlist {
      right: 1rem;
      left: 0rem;
    }

    top: 0.1rem;
    left: -0.5rem;
  }

  .timelinefixed {
    top: 5.5rem !important;
  }

  ul {
    &.headernavdropmd {
      position: relative;
      right: 2.6rem;
      top: -0.2rem;
    }

    &.callmerightbox {
      position: absolute;
      right: -77px;
      top: -10px;
    }
  }

  header ul li {
    list-style-type: none;
    display: inline-block;
    margin-right: 6px;
  }

  li.headullipaddreight {
    padding-right: 11px;
  }

  .callmebgclr {
    top: 12px;
  }

  .exprit_infoiconpan_boxbottom {
    font-size: 11px;
    font-weight: 500;
  }
  .client_name_header_fullname {
    max-width: 300px !important;
    font-size: 12px;
    font-weight: 500;
  }
  .client_name_header {
      max-width: 80px !important;
  }
}

@media  (min-width: 1282px) and (max-width: 1400px) {
  .exprit_infoiconpan_boxbottom {
    font-size: 12px;
    font-weight: 500;
  }
  .client_name_header_fullname {
    max-width: 300px !important;
    font-size: 12px;
    font-weight: 500;
  }
  .client_name_header {
      max-width: 90px !important;
  }
}







.k-grid-header .k-header {
  text-align: center !important;
}

.k-grid-header-wrap span.k-icon.k-i-more-vertical {
  padding-left: 13px !important;
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap {
  padding: 0px !important;
}

.k-multiselect .k-multiselect-wrap .k-button,
.k-dropdowntree .k-multiselect-wrap .k-button {
  padding: 4px !important;
}

.k-button {
  // background-color: #e57373 !important;
  // border-color: #e57373 !important;
  color: #212529 !important;
  background-image: none !important;

  &:hover,
  &.k-state-hover {
    background-color: #31719b !important;
    border-color: #31719b !important;
    color: #fff !important;
    background-image: none !important;
  }
}

.k-grid-header-wrap span.k-icon.k-i-more-vertical {
  padding-left: 0px !important;
  font-size: 0.8rem;
}

th.active .k-icon {
  color: #fc7d72 !important;
}

.k-i-more-vertical::before {
  content: "\e129" !important;
}

/*.k-i-more-vertical::before {
       content: "\e12a";
   }*/

span.k-widget.k-dropdown.header_select.headerselect span {
  &.k-dropdown-wrap span.k-input {
    height: calc(0.9em + 5px) !important;
    padding: 0px 0px 0px 5px !important;
  }

  &.k-select {
    padding: 0px 0px !important;
    width: calc(0.9em + 5px) !important;
  }
}

.header_select {
  width: 9.4em !important;
}

.client_name_header_fullname {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
}

.client_name_header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  display: inline-block;

  /* line-height: 9px; */
}

ul.compboxtoppadd a.btn.btn-default:focus,
span.exprit_dashboard_iconbox a.btn.btn-default:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
}

.processSync {
  position: fixed;
  z-index: 999;
  background: white;
  width: 50%;
  bottom: 60px;
  padding: 10px;
  left: 25%;
  box-shadow: 0px 0px 2px 3px #20202033;
  border-radius: 12px;
}

.client-status-show {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  line-height: 2rem;
  font-size: 0.7rem;

  span {
    color: #31719b;
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }

}

.header-comptation {
  background-color: #31719b;
  color: #fff;
  padding: 2px 10px 3px 10px !important;
  position: relative;
  border-radius: 40px;
  font-size: 12px;
  top: 0px;
  font-weight: 500;

  &:hover {
    color: white;
  }
}







li.regimebox_header {
  vertical-align: middle;
  font-size: 12px;
  font-weight: normal;



  .switch.efiling_header_sw {
    position: relative;
    display: inline-block;
    width: 23px;
    height: 11px;
    top: 5px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked {
        + {
          .slider {
            background-color: #cccbcb;

            &:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
              left: -9px;
            }
          }
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #31719b;
      transition: .4s;

      &:before {
        position: absolute;
        content: "";
        height: 7.3px;
        width: 3px;
        top: 2px;
        left: 3px;
        background-color: rgb(242, 242, 242);
        transition: 0.4s;
        outline-color: rgb(242, 242, 242);
      }
    }

    .slider.round {
      border-radius: 34px;

      &:before {
        border-radius: 16%;
      }
    }
  }

}


/*========= 3 step toggle    ===========*/

.efiling_statetoggle {
  background: rgba(165, 170, 174, 0.25);
  box-shadow: inset 0 2px 8px 0 rgba(165, 170, 174, 0.25);
  border-radius: 24px;
  display: inline-block;
  overflow: hidden;
  display: inline-flex;
  transition: all 500ms ease;
  vertical-align: inherit;
  top: 1px;
  position: relative;
  cursor: pointer;

  & .efiling_statetoggle_button {
    border-radius: 3px !important;
    height: 11px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px !important;
    background-color: transparent;
    border: 0px solid transparent;
    margin: 0px 0px;
    color: #727C8F;
    transition: all 0.5s ease;

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }

  & .efiling_statetoggle_button.active {
    border: 1px solid rgba(207, 207, 207, 0.6);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    color: #6744B1;
    font-weight: 500;
    transition: all 0.4s ease-in;
    background-color: #31719b;
    border-radius: 11px !important;
    min-width: 10px;
    cursor: pointer;

  }

  & .efiling_statetoggle_button.efiling_togglebtn_02.active {
    border: 1px solid rgba(207, 207, 207, 0.6);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    color: #6744B1;
    font-weight: 500;
    transition: all 0.4s ease-in;
    background-color: #fff;
    border-radius: 11px !important;
    min-width: 10px;
    cursor: pointer;

  }

  & .efiling_statetoggle_button.efiling_togglebtn_03.active {
    border: 1px solid rgba(207, 207, 207, 0.6);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    color: #6744B1;
    font-weight: 500;
    transition: all 0.4s ease-in;
    background-color: green;
    border-radius: 11px !important;
    min-width: 10px;
    cursor: pointer;

  }
}

.efiling_statetoggle_button:focus {
  outline: none;
}




.efiling_statetoggle_ht02 {
  background: rgba(165, 170, 174, 0.25);
  box-shadow: inset 0 2px 8px 0 rgba(165, 170, 174, 0.25);
  border-radius: 24px;
  display: inline-block;
  overflow: hidden;
  display: inline-flex;
  transition: all 500ms ease;
  vertical-align: inherit;
  top: 1px;
  position: relative;
  cursor: pointer;

  & .efiling_statetoggle_button__ht02 {
    border-radius: 3px !important;
    height: 11px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px !important;
    background-color: transparent;
    border: 0px solid transparent;
    margin: 0px 0px;
    color: #727C8F;
    transition: all 0.5s ease;

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
  }

  & .efiling_statetoggle_button__ht02.active {
    border: 1px solid rgba(207, 207, 207, 0.6);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    color: #6744B1;
    font-weight: 500;
    transition: all 0.4s ease-in;
    background-color: #31719b;
    border-radius: 11px !important;
    min-width: 10px;
    cursor: pointer;

  }

  & .efiling_statetoggle_button__ht02.efiling_togglebtn_02.active {
    border: 1px solid rgba(207, 207, 207, 0.6);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    color: #6744B1;
    font-weight: 500;
    transition: all 0.4s ease-in;
    background-color: #fff;
    border-radius: 11px !important;
    min-width: 10px;
    cursor: pointer;

  }

  & .efiling_statetoggle_button__ht02.efiling_togglebtn_03.active {
    border: 1px solid rgba(207, 207, 207, 0.6);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    color: #6744B1;
    font-weight: 500;
    transition: all 0.4s ease-in;
    background-color: green;
    border-radius: 11px !important;
    min-width: 10px;
    cursor: pointer;
  }
}


.efiling_statetoggle_button__ht02:focus {
  outline: none;
}

span.pre_current_textfsgrey {
  color: #525252;
  width: 70px;
  display: inline-block;
  font-weight: bold;
}

span.efilinghead_textblue {
  color: #31719b;
}

.reg_rightsidetextfs {
  cursor: pointer;
  color: #31719b;

}

.reg_rightsidetextfs:hover {
  text-decoration: underline;

}


.header-tax-box {
  width: auto;
  text-align: center;
  display: inline-block;
  padding: 0 5px;
}

.header-tax-amount {
  color: black;
  background: #ffe6e4;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 2px;
}

.efiling-warn {
  background: #f0e2a8 !important;
  font-size: 12px;
  text-align: center;
  line-height: 26px;
}

.header-sync-class {
  top: -8px;
}

.sync_status {
  z-index: 9999;
}

/*========= end  3 step toggle    ===========*/

.progress-sync-popup {
  .progress-bar {
    overflow: visible !important;
  }


  .show-progress-count {
    float: right;
    position: absolute;
    right: 0;
    margin-right: 20px;
  }
}

.rebate87-warning {
  border-radius: 5px;
  font-style: italic;
  background: #fa988f !important;
  font-size: 12px;
  text-align: center;
  line-height: 26px;
  text-align: left;
  padding: 2px 4px;
  cursor: pointer;
}
.row-highlighted{
  background: #f29d95 !important;
}