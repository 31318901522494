h4.Verify_your_account_whatsapp_notextfs {
  font-size: 1rem;
  font-weight: 500;
}

.Verify_your_account_whatsapp_modal {
  margin: 3rem auto auto auto;
}
.Verify_your_account_whatsapp_modal .Verifyyour_account_whatsapp_rightbox {
  padding-top: 4rem;
  /* style.css */
}
.Verify_your_account_whatsapp_modal .Verifyyour_account_whatsapp_rightbox p.Verifyyour_account_whatsapp_textfs {
  font-size: 12px;
  font-weight: 500;
}
.Verify_your_account_whatsapp_modal .Verifyyour_account_whatsapp_rightbox .verifyacwhatsapp_resendotp_box {
  padding-top: 2rem;
  text-align: center;
}
.Verify_your_account_whatsapp_modal .Verifyyour_account_whatsapp_rightbox .verifyacwhatsapp_resendotp_box button.verifyacwhatsapp_resendotp_btnbgclr {
  background-color: #31719b;
  border-color: #31719b;
  border-radius: 28px;
  color: #fff;
  padding: 3px 10px 5px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  border: 0px;
}
.Verify_your_account_whatsapp_modal .Verifyyour_account_whatsapp_rightbox .verifyacwhatsapp_resendotp_box span.verifyacwhatsapp_resendotp_timetextfs {
  color: #fc7d72;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: -1px;
  padding-left: 5px;
}
.Verify_your_account_whatsapp_modal .Verifyyour_account_whatsapp_rightbox .verifyacwhatsapp_resendotp_box span.verifyacwhatsapp_resendotp_donttextfs {
  position: relative;
  top: 15px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #2a73b3;
  cursor: pointer;
}
.Verify_your_account_whatsapp_modal .Verifyyour_account_whatsapp_rightbox input.verifyacwhatsapp_inputsbox {
  width: 20px;
  border: none;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
  margin: 0 10px;
  text-align: center;
  font-size: 18px;
  /* cursor: not-allowed;
  pointer-events: none; */
}
.Verify_your_account_whatsapp_modal .Verifyyour_account_whatsapp_rightbox input.verifyacwhatsapp_inputsbox:focus {
  border-bottom: 3px solid orange;
  outline: none;
  box-shadow: none;
}
.Verify_your_account_whatsapp_modal .Verifyyour_account_whatsapp_rightbox input.verifyacwhatsapp_inputsbox:nth-child(1) {
  cursor: pointer;
  /* pointer-events: all; */
}

.verifyaccwhatsapp_registeredemail_modalbgclr {
  background-color: rgba(34, 34, 34, 0.8392156863);
}

.verifyaccwhatsapp_registeredemail_modalbgclr {
  max-width: 370px;
}/*# sourceMappingURL=Verify_your_account_through_whatsapp.css.map */