p.youtrialexptextfs {
    font-size: 12px;
  }
  
  .modal.yourtrialexpirpoup.show .modal-dialog {
    margin: 10rem auto 0rem;
  }
  
  .modal-header.yourtrialexpirhead {
    padding: 1px 7px;
    background-color: #eee;
  
    .trialexpttle {
      font-size: 1rem;
      padding-top: 4px;
    }
  }
  
  button.btn.btn-default.trialexppaynowbtn {
    background-color: #31719b;
    border-color: #31719b;
    border-radius: 28px;
    color: #fff;
    padding: 7px 15px;
    font-size: 12px;
    font-weight: 500;
  }
  
  .modal-header.yourtrialexpirhead button.close:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
  }
  
  @media only screen and (min-width: 576px) {
    .yourtrialexpirpoup .modal-dialog {
      max-width: 300px;
      margin: 1.75rem auto;
    }
  }