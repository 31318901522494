@use '../base';

.maintenance_page {
  margin: 0;
  background-color: hsl(180deg 2% 91%);
   @include base.fontFamily();
  padding-top: 20px;
  height: 100vh;
}

.mainwrapp {
  margin: auto auto;
  background-color: hsl(180deg 2% 91%);
  text-align: center;
}

.maintoptitle {
  font-size: 28px;
  font-weight: 700;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #313131;
  text-transform: uppercase;
  text-align: center;
}

.subtitll {
  padding-top: 0px;
  margin-top: 0px;
  font-size: 14px;
  color: #efa500;
  margin-bottom: 0px;
  text-transform: uppercase;
  text-align: center;
}

p.bootomtext {
  line-height: 18px;
  font-size: 12px;
  padding-bottom: 0px;
  color: #313131;
  margin-top: 0px;
  text-align: center;
  font-weight: 600;
}

.backtohomeclr {
  a {
    font-size: 12px;
    padding: 0px 46px;
    color: #31719b;
    font-weight: 500;
    text-decoration: none;
  }

  margin-bottom: 0px;
  z-index: 5;
  padding-top: 5px;
  position: absolute;
}

.animationBox {
  margin: 0 auto;
  width: 500px;
}
