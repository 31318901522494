.modal.openkdkconnectmod.show .modal-dialog {
  width: 600px;
  margin: 1rem auto;
}

.modal-header.openkdkconnectheader {
  padding: 5px 27px 0px 15px;
  background-color: #efecec;
}

.openkdkconnecttitle.modal-title.h4 {
  font-size: 1.1rem;
  top: -9px;
  position: relative;
  font-weight: 500;
}

.modal-header.openkdkconnectheader button.close {
  padding: 20px 0px 0px 0px;

  &:focus {
    outline: 0px auto -webkit-focus-ring-color !important;
  }
}

p.textfs12 {
  font-size: 12px;
}

h4.textfs1rem {
  font-size: 12px;
  font-weight: 500;
  padding: 0px 0px;
}

ol.openkdkconlist {
  padding-left: 29px;

  li {
    font-size: 12px;
    line-height: 20px;
  }
}

p.textfs14 {
  font-size: 13px;
}

span.textboldfs {
  font-size: 12px;
  font-weight: 600;
}

.kdkconttextblod {
  font-weight: 500;
}

p {
  &.tryingtextclrylw {
    text-align: center;
    font-size: 12px;
    color: #ffd42e;
  }

  &.tryingtextclrygr {
    text-align: center;
    font-size: 12px;
    color: #37be79;
  }
}

button.btn.btn-default {
  &.launchcontbtn {
    border: 1px solid #33729c;
    outline-color: #33729c;
    border-radius: 40px;
    color: #fff;
    background-color: #33729c;
    padding: 8px 15px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 10px;
  }

  &.openkdkconnbtn {
    border: 1px solid #33729c;
    outline-color: #33729c;
    border-radius: 40px;
    color: #fff;
    background-color: #33729c;
    padding: 7px 30px;
    font-size: 12px;
    font-weight: 500;
  }
}

/*New connector CSS*/

span {
  &.connector-trying-textfsbgclr {
    background-color: #f9f3d7;
    padding: 3px 40px 5px 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  &.connector-mandatory-textfs {
    font-size: 1rem;
    color: #31719b;
    font-weight: 500;
    vertical-align: super;
  }

  &.connector-infoicon-clr {
    color: #fc7d72;
    font-size: 27px;
    vertical-align: text-top;
    padding-right: 6px;
    position: relative;
    top: -7px;
  }
}

button.btn.btn-default.download-btnbgclr {
  background-color: #ffffff;
  padding: 0px 5px 5px 5px;
  border-color: #31719b;
  font-size: 12px;
  border-radius: 40px;
  color: #31719b;
  font-weight: 500;
}

.leftside-border {
  border-left: 1px solid #ddd;
  border-width: 2.2px;
  padding: 0px 1px 0px 18px;
}

p.down-alraedytextfs {
  font-size: 14px;
  font-weight: 500;
}

a.clickhererefer-fs {
  color: #31719b;
}

h4.infoicon-left {
  position: relative;
  left: -1rem;
}

fieldset {
  display: block;
  margin: 9px 2px 2px !important;
  background-color: #fff;
  padding: 0.35em 0.75em 0.625em !important;

  /* border: 2px groove (internal value); */
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

.fade.modal.cashcreditledgerpoup.modal.show .modal-dialog {
  max-width: 850px;

  .modal-header.cashhead.modal-header {
    padding: 0px 15px;
    background-color: #eee;

    .modal-title.cashledgertext {
      font-size: 1rem;
      font-weight: 500;
      padding: 4px 0px 4px 0px !important;
    }
  }
}

.modal-header.cashhead.modal-header button.close {
  top: 4px !important;
  position: relative;
}

/* ================== start media ==========*/
@media (max-width: 767px) {
  .modal.openkdkconnectmod.show .modal-dialog {
    width: 98%;
    margin: 1rem auto;
  }
}
