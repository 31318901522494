@use 'base';


.backbutton_fixedrow {
    position: fixed;
    z-index: 9;
    background-color: #fff;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 5px;
}

.App {
  text-align: center;
}

body {
  background-color: #ffffff !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-weight: 500 !important;
  @include base.fontFamily();

}

h1, h2, h3, h4, h5 {
  font-weight: 500 !important;
  @include base.fontFamily();
}

.backtoclr {
  color: #fc7d72;
  font-size: 12px;
  padding: 4px 0;
  font-weight: 500;
  @include base.fontFamily();
}
a.backtoclr:hover {
    color: #fc7d72;
    text-decoration: none !important;
  }
  button.backbtn_txetfsclr {
    text-decoration: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.k-filter-menu-container button.k-button{
	display: block;
}

button.k-button {
  display: none;
}

.k-actions button.k-button {
  display: block;
}

.disabled {
  opacity: .5;
}


.dropzonestyle {
  height: 150px;
  width: 100%;
}
.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  height: 200px;
  justify-content: center;
}

 /*  ---- start Intro css ------ */

.kWwJhA {
	z-index: 1038 !important;
	color: rgb(0 0 0 / 100%) !important;
}
.dvlaPW {
	z-index: 1039 !important;
}
.diKbXs {
	justify-content: left !important;
}
.sc-ifAKCX {
	padding: 14px 14px !important;
	font-size: .9rem!important;
	font-weight: 600!important;
	color: #333 !important;
	max-width: 250px !important;
	border-radius: 10px !important;
	background-color: #fff !important;
	box-shadow: none !important;
	font-family: -apple-system,BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"!important;
	transition: transform 0.8s !important;
	z-index: 1039 !important;
}
.fyrYpc {
	border: 1px solid #333 !important;
	&:hover {
		&:before {
			display: none;
		}
	}
}
.sc-bZQynM {
	position: relative !important;
	margin-top: 30px !important;
}
.fHIrZs {
	position: absolute !important;
	top: -25px !important;
	left: 30% !important;
}
.eTpeTG {
	font-size: 12px !important;
	font-weight: 500 !important;
	padding: 4px 8px 5px 10px !important;
	border: 1px solid #31719b!important;
	border-radius: 40px !important;
	background-color: #fff !important;
	bottom: 0.9rem !important;
	color: #333!important;
	top: auto !important;
	width: 70px !important;
	height: auto !important;
	right: 10px !important;
	svg {
		display: none !important;
	}
	&::before {
		content: 'End Tour' !important;
	}
}
.reactour__dot--is-active {
	background-color: #fc7d72!important;
}
.kcbIHb {
	color: #fff!important;
	padding: 4px 12px 5px 20px !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	text-shadow: 0px 0px 0 #31719b !important;
	border-radius: 40px!important;
	border: 1px solid #31719b !important;
	background-color: #31719b !important;
	float: left !important;
	border-color: #31719b !important;
	position: relative !important;
	margin-right: 0px !important;
	&::before {
		content: url(./images/danglrighttwclr8x8.png) !important;
		position: absolute !important;
		left: 8px !important;
	}
}
.fYzjNt {
	color: #fff!important;
	padding: 4px 20px 5px 12px !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	text-shadow: 0px 0px 0 #31719b !important;
	border-radius: 40px!important;
	border: 1px solid #31719b !important;
	background-color: #31719b !important;
	float: left !important;
	border-color: #31719b !important;
	position: relative !important;
	margin-left: 10px !important;
	&::after {
		content: url(./images/danglleftwclr8x8.png) !important;
		position: absolute !important;
		right: 8px !important;
	}
}
.introjs-skipbutton {
	font-size: 12px !important;
	font-weight: 100 !important;
	padding: 8px 10px !important;
	background-color: #fdf4e7 !important;
	bottom: 0.6rem !important;
	color: #31719b!important;
	position: relative !important;
	border-radius: 1px !important;
}
.introjs-arrow {
	&.top {
		top: -10px;
		left: 6px !important;
		border-bottom-color: #ffffff;
		display: none !important;
	}
	&.right {
		display: none !important;
	}
	&.left {
		display: none !important;
	}
}
.introjs-tooltipbuttons {
	.introjs-button {
		&:focus {
			box-shadow: 0 0 0 0.1rem rgb(158 158 158 / 39%) !important;
			background-color: #31719b !important;
		}
	}
}
button {
	&.sc-bdVaJa {
		&.lfvGpo {
			&.sc-htpNat {
				&.gnFBec {
					display: none;
				}
				&.dobmSV {
					display: none;
				}
			}
		}
	}
}
.introjs-overlay {
	z-index: 1000 !important;
}
.introjs-helperLayer {
	z-index: 1001 !important;
}
.introjs-tooltipReferenceLayer {
	z-index: 1003 !important;
}
 /*  ---- End Intro css ------ */

 .Toastify__toast-container {
    width: auto!important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
	color: #155724!important;
    background-color: #d4edda!important;
    border-color: #c3e6cb!important;
}
.Toastify__progress-bar--success {
	background-color: #155724!important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
	color: #721c24!important;
    background-color: #f8d7da!important;
    border-color: #f5c6cb!important;
}
.Toastify__progress-bar--error {
	background-color: #721c24!important;
}
.Toastify__close-button {
    color: #000!important;
}
.excltionicon{
	width: 12px;
	margin-right: 3px;
  }
.input-error1  {
	border: 1px solid #fc6a6a!important;
	box-shadow: none!important;
}