body {
   background-color: #ffffff !important;
   font-size: 1rem;
   font-weight: normal;
   line-height: 1.5;
   color: #212529;
   text-align: left;
   -webkit-font-smoothing: antialiased;
   font-weight: 500 !important;
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.oldvs_newrem_rightside_popupbox {
   position: fixed;
   padding: 0 0.4rem 0.1rem 0.7rem !important;
   background-color: #31719b;
   color: #fff;
   z-index: 20;
   top: 62%;
   -webkit-transform: rotate(270deg);
   transform: rotate(270deg);
   border-radius: 5px 5px 0px 0px;
   overflow: visible;
   right: -8rem;
   -webkit-transition: all 1.25s ease-in-out;
   -moz-transition: all 1.25s ease-in-out;
   -o-transition: all 1.25s ease-in-out;
   transition: all 1.25s ease-in-out;
   cursor: pointer;
   animation: oldrightsummanimat;
   animation-duration: 3s;
   animation-iteration-count: infinite;
   animation-timing-function: ease-in;
   animation-direction: alternate-reverse;



   & .old_compare_btnfs {
      color: #fff !important;
      font-size: 15px;
      font-weight: 600;
      padding: 4px 15px 10px 15px;
      border: 0px;
   }

   span.oldvs_uploaddataicon {
      -webkit-transform: rotate(88deg);
      transform: rotate(270deg);
      transform-box: fill-box;
      margin-top: 0.4rem;
      top: -28px;
      left: 6.7rem;
      position: absolute;
   }


}

.oldvs_newrem_rightside_popupbox:hover {
   right: -6rem !important;
   -webkit-transition: all 1.25s ease-in-out;
   -moz-transition: all 1.25s ease-in-out;
   -o-transition: all 1.25s ease-in-out;
   transition: all 1.25s ease-in-out;
   -webkit-animation-play-state: paused;
   -moz-animation-play-state: paused;
   -o-animation-play-state: paused;
   animation-play-state: paused;
   cursor: pointer;
}

@keyframes oldrightsummanimat {
   0% {
      right: -8rem;
      right: -8rem;
   }

   100% {
      right: -6rem;
      right: -6rem;
   }
}



/*=========== old vs new reg popup css   ===========*/
.modal.fade.comprsignold_popup.show .modal-dialog {
   margin: 1rem auto 1rem auto;
   max-width: 800px;
   margin-top: 1rem;
   margin-right: -0rem;



   /* Modal Content expressit_3ca3cdopencapt_viewconsolidatedbp */
   .modal-content.olsvsnew_contanimat {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 0;
      border: 1px solid #888;
      width: 100%;
      /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19); */
      -webkit-animation-name: animaterightoldvsnew;
      -webkit-animation-duration: 1.25s;
      animation-name: animaterightoldvsnew;
      animation-duration: 1.25s;
      -webkit-transition: all 1.25s ease-in-out;
      -moz-transition: all 1.25s ease-in-out;
      -o-transition: all 1.25s ease-in-out;
      transition: all 1.25s ease-in-out;
   }

   /* Add Animation */
   @-webkit-keyframes animaterightoldvsnew {
      from {
         right: -300px;
         opacity: 0
      }

      to {
         right: 0;
         opacity: 1
      }
   }

   @keyframes animaterightoldvsnew {
      from {
         right: -300px;
         opacity: 0
      }

      to {
         right: 0;
         opacity: 1
      }
   }


   & .modal-header.comprsignold_popup_header {
      background-color: #eee;
      padding: 0px 15px;

      & h4.modal-title {
         font-size: 1rem;
         font-weight: 500;
         padding: 4px 0px 4px 0px;

      }

      & button.close {
         position: relative;
         top: 5px;
      }

   }

   table.table.table-striped.expit_comp_newold_tb {
      & thead tr th {
         font-size: 12px;
         font-weight: 500;
         text-align: center;
         border-top: 0px;
         padding-top: 0px;
         padding-bottom: 5px;
         vertical-align: inherit;

      }

      & tr td {
         font-size: 11px;
         font-weight: 500;
         padding: 0.25rem 0.75rem;


      }

      & .comp_newold_bgclr_coral {
         background-color: #f9948d;
         color: #fff;
      }

      & .oldvsnewtaxreg_overflowtext {
         max-width: 140px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
         word-wrap: break-word;
      }

      & .comp_newold_bgclr_blue {
         background-color: #2f61ad;
         color: #fff;
      }

      & .comp_newold_bgclr_purpal {
         background-color: #f3f3f3;
      }

      & .comp_newold_bgclr_lightyell {
         background-color: #fdf4e7;
      }

      & tr.comp_newold_totaltextbold td {
         font-weight: 700 !important;
      }

      & td.comp_newold_sectbgclr_lightblue {
         background-color: #ded4ef;
      }

   }




}



/*------------ start media code  -------------*/
@media (max-width: 768px) {
   .oldvs_newrem_rightside_popupbox {
      top: 62%;
   }

}

@media (max-width: 769px) and (max-width: 1024px) {
   .oldvs_newrem_rightside_popupbox {
      top: 65%;
   }

}

@media (max-width: 1025px) and (max-width: 1349px) {
   .oldvs_newrem_rightside_popupbox {
      top: 68%;
   }

}

@media (min-width: 1500px) and (max-width: 1951px) {
   .oldvs_newrem_rightside_popupbox {
      top: 60%;
   }

}


@media (min-width: 1952px) and (max-width: 2251px) {
   .oldvs_newrem_rightside_popupbox {
      top: 55%;
   }

}

@media (min-width: 2252px) {
   .oldvs_newrem_rightside_popupbox {
      top: 58%;
   }

}